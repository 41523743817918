.tastes {
  margin-top: -220px;
  padding: 192px 0 114px;

  @include viewport--md {
    margin-top: -161px;
    padding: 153px 0 74px;

    .taste-card {
      width: 222px;
      height: 262px;
    }
  }

  @include viewport--sm {
    padding: 172px 0 63px;

    .taste-card {
      width: 160px;
      height: 200px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    overflow: hidden;
    transform: skew(0,-6.3deg);

    opacity: 1;
    visibility: visible;

    transition: position $default-transition-settings, opacity $default-transition-settings, visibility $default-transition-settings;

    &.hide {
      opacity: 0;
      visibility: hidden;
    }

    &.fix {
      position: absolute;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    max-width: 1700px;

    overflow: hidden;

    @include viewport--sm {
      flex-wrap: wrap;
    }
  }

  &__title {
    max-width: 580px;
    // margin-left: -220px;
    margin: 58px 0;

    color: $color-white;
    font-family: "Appetite";
    font-size: 70px;
    line-height: 60px;

    overflow: hidden;


    @include viewport--md {
      max-width: 420px;
      margin: 28px 0 0;
      font-size: 50px;
      line-height: 50px;
    }

    @include viewport--sm {
      max-width: 320px;

      font-size: 34px;
      line-height: 30px;
    }
  }

  &__title-animated {
    display: block;
  }

  &__link {
    margin: 86px 22% 0 20px;

    overflow: hidden;

    @media(max-width: 1899px) {
      margin-right: 17%
    }

    @include viewport--md {
      margin: 66px 8% 0 20px;
    }

    @include viewport--sm {
      margin: 19px 0 0 0;
    }
  }

  &__slider {
    display: flex;
    // overflow: hidden;
    margin: -15px 0 0 -120px;

    transform: skewY(-4deg);

    .swiper-slide {
      transform: scale(1) skewY(3deg);
      transition: transform 0.3s ease-in;
    }

    .swiper-slide-active {
      transform: translateZ(0) scale(1.1) skewY(3deg);
      z-index: 1;
    }

    .swiper-slide-prev {
      z-index: 2;
    }

    @include viewport--md {
      margin: 37px 0 0 -90px;
    }

    @include viewport--sm {
      margin-top: 0;
      margin-left: -40px;

      .swiper-slide-active {
        transform: translateZ(0) scale(1.2) skewY(3deg);
      }
    }
  }

  &__slider-list {
    display: flex;
    max-width: 100%;
    width: 100%;

    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__slider-item {
    flex-shrink: 0;
    // max-width: 501px;

    margin-right: -120px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 60px;
    padding-bottom: 60px;

    overflow: hidden;

    @include viewport--md {
      margin-right: -90px;
      padding-left: 90px;
      padding-right: 90px;
    }

    @include viewport--sm {
      margin-right: -45px;
      padding-left: 45px;
      padding-right: 45px;
      padding-top: 68px;
      padding-bottom: 51px;
    }

    &.overflow {
      overflow: visible;
    }
  }

  &__note {
    display: flex;
    align-self: flex-end;
    width: 384px;
    margin: -10px 16.2% 0 0;

    color: $color-white;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 0.5px;

    transform: skew(0,0.7deg);

    overflow: hidden;

    @media(max-width: 1899px) {
      margin-right: 5%;
    }

    @include viewport--md {
      margin: -1px 2.5% 0 0;
      width: 284px;

      font-size: 18px;
      line-height: 28px;
      letter-spacing: .05px;
    }

    @include viewport--sm {
      align-self: flex-start;
      margin: 0 0 0 19px;
    }
  }

  &__note-animated {
    display: block;
  }

  &__details {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: -86px;
    margin-bottom: -70px;

    opacity: 1;
    visibility: visible;

    transition: position $default-transition-settings, opacity $default-transition-settings, visibility $default-transition-settings;

    &.hide {
      opacity: 0;

      visibility: hidden;
    }

    &.fix {
      position: absolute;
      top: -100%;
    }

    @include viewport--md {
      margin-top: -100px;
      margin-bottom: -20px;
    }

    @media(max-width: 845px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media(max-width: 939px) {
      padding-left: 0;
      padding-right: 0;
    }

    @include viewport--sm {
      margin-top: -60px;
      margin-bottom: 18px;
    }
  }

  &__details-slider-btns-wrap {
    position: absolute;
    bottom: 190px;
    left: 4px;

    display: flex;
    z-index: 1;

    @media (max-width: 939px) {
      left: 40px;
    }

    @include viewport--sm {
      bottom: 438px;
      left: 19px;
    }
  }

  &__details-btn-next,
  &__details-btn-prev {
    left: 46px;

    padding: 0;

    width: 32px;
    height: 32px;

    svg {
      fill: $color-white;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__details-btn-next {
    margin-left: 10px;
  }

  &__details-close-btn-wrapper {
    position: absolute;
    top: 32px;
    right: 36px;

    display: flex;
    align-items: center;
    height: 55px;

    overflow: hidden;
    z-index: 1;

    @include viewport--md {
      top: 14px;
      right: 65px;
    }

    @include viewport--sm {
      top: 44px;
      right: 19px;
    }
  }

  &__details-close-btn {
    align-self: center;
    width: 47px;
    height: 47px;
    padding: 0;

    svg {
      fill: $color-white;
    }

    @include viewport--sm {
      width: 40px;
      height: 40px;
    }
  }

  &__details-slider {
    position: relative;
    display: flex;

    overflow: hidden;
  }

  &__details-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__details-item.swiper-slide-active {
    position: relative;
    z-index: 1;
  }

  .detailed-card__img-slider {
    margin-top: 30px;
    margin-bottom: 14px;

    @include viewport--sm {
      padding-bottom: 12px;
    }
  }
}

