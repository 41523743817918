@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Appetite-Pro";

  font-display: swap;
  src: url("../fonts/appetite-pro-700.woff2") format("woff2"),
  url("../fonts/appetite-pro-700.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Akrobat";

  font-display: swap;
  src: url("../fonts/akrobat-400.woff2") format("woff2"),
  url("../fonts/akrobat-400.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Akrobat";

  font-display: swap;
  src: url("../fonts/akrobat-700.woff2") format("woff2"),
  url("../fonts/akrobat-700.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Akrobat";

  font-display: swap;
  src: url("../fonts/akrobat-700.woff2") format("woff2"),
  url("../fonts/akrobat-700.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Akrobat";

  font-display: swap;
  src: url("../fonts/akrobat-300.woff2") format("woff2"),
  url("../fonts/akrobat-300.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Appetite";

  font-display: swap;
  src: url("../fonts/appetite-pro-bold.woff2") format("woff2"),
  url("../fonts/appetite-pro-bold.woff") format("woff");
}
