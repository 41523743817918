.brand {
  &__inner {
    position: relative;

    display: flex;
    padding-top: 115px;
    padding-bottom: 216px;

    @include viewport--md {
      padding-top: 62px;
      padding-bottom: 70px;
    }

    @include viewport--sm {
      display: block;
      padding-top: 54px;
      padding-right: 12px;
      padding-bottom: 38px;
      padding-left: 12px;
    }
  }

  &__image {
    flex-shrink: 0;
    width: 405px;
    height: 362px;
    margin-right: 40px;
    margin-top: 33px;

    transform: translateZ(0);
    filter: drop-shadow(0 0 25px #fff4b4);

    img {
      display: block;
      height: 100%;

      object-fit: cover;

      clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 94%);
    }

    @include viewport--md {
      width: 308px;
      height: 275px;
      margin-top: 16px;
    }

    @include viewport--sm {
      position: absolute;
      top: 102px;

      width: 215px;
      height: 191px;
      margin-top: 0;
      margin-right: 0;
    }
  }

  &__text {
    width: 590px;
    margin-left: auto;

    h2,
    .brand__title {
      margin: 0;
      margin-bottom: 20px;

      color: $color-white;
    }

    h3,
    strong {
      display: block;
      margin-bottom: 25px;

      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: $color-madras;
    }

    p {
      margin: 0;

      font-weight: 700;
      font-size: 22px;
      line-height: 36px;
      color: $color-yukon-gold;

      & + p {
        margin-top: 1em;
      }
    }

    @include viewport--md {
      width: 563px;

      h2,
      .brand__title {
        margin-bottom: 6px;
      }

      h3,
      strong {
        margin-bottom: 14px;

        font-size: 22px;
        line-height: 40px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }

    @include viewport--sm {
      width: 100%;

      h2,
      .brand__title {
        font-size: 26px;
        line-height: 36px;

        margin-bottom: 234px;
      }

      h3,
      strong {
        margin-bottom: 22px;

        line-height: 28px;
      }

      p {
        line-height: 22px;
      }
    }
  }
}
