.first-screen {
  padding-top: $header-height--lg;

  @include viewport--md {
    padding-top: $header-height--sm;
  }

  @include viewport--sm {
    padding-top: 0;
  }
}
