.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin: 0;
  padding: 14px 22px;

  font-family: $font-family--main;
  font-size: 28px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  color: $color-black;

  cursor: pointer;
  outline: none;

  transition: color $default-transition-settings, background-color $default-transition-settings;

  @include viewport--sm {
    padding: 10px 18px;

    font-size: 21px;
    line-height: 19px;
  }

  &--orange {
    color: $color-white;
    background-color: $color-orange;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-raw-umber;
      }
    }
  }

  &--border-flamingo {
    border: 2px solid $color-flamingo;

    transition: border $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-color: $color-salmon;
      }
    }
  }

  &--skew-7 {
    transform: skewY(-7deg);

    svg {
      transform: skewY(7deg);
    }
  }

  &--skew-12 {
    transform: skewY(-12deg);

    svg {
      transform: skewY(12deg);
    }
  }
}
