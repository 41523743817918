.achievements {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  list-style: none;

  line-height: normal;

  @include viewport--sm {
    flex-direction: column;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    padding-right: 30px;

    b {
      font-family: $font-family--title;
      font-size: 100px;
    }

    span {
      margin-top: 4px;

      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
    }

    &:not(:nth-child(-n+3)) {
      margin-top: 30px;
    }

    @include viewport--md {
      &:nth-child(3n + 2) {
        padding-left: 43px;
      }

      &:nth-child(3n + 3) {
        padding-left: 76px;
      }

      b {
        font-size: 68px;
      }

      span {
        margin-top: 0;

        font-size: 22px;
        line-height: 22px;
      }
    }

    @include viewport--sm {
      width: 100%;

      & + & {
        margin-top: 20px;
      }

      &:nth-child(3n + 2) {
        padding-left: 0;
      }

      &:nth-child(3n + 3) {
        padding-left: 0;
      }

      b {
        font-size: 40px;
      }

      span {
        margin-top: 2px;
      }
    }
  }
}
