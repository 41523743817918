.menu {
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  color: $color-clincker;

  @include viewport--md {
    font-size: 22px;
  }

  @include viewport--sm {
    font-size: 38px;
    line-height: 64px;

    color: $color-white;
  }

  &__list {
    display: flex;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    margin-right: 31px;

    &:last-of-type {
      margin-right: 0;
    }

    @include viewport--md {
      margin-right: 35px;
    }

    @include viewport--sm {
      margin-right: 0;
    }
  }

  &__link {
    display: block;
    color: inherit;

    outline: none;
    transition: opacity $default-transition-settings;

    &.active {
      color: $color-sunglow;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
}
