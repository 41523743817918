.clipped-icon {
  display: flex;
  width: 117px;
  height: 112px;

  transform: translateZ(0);
  filter: drop-shadow(0 0 25px $color-shalimar);

  @include viewport--sm {
    width: 72px;
    height: 69px;
  }

  svg {
    fill: $color-white;
  }

  &--green {
    .clipped-icon__wrapper {
      background-color: $color-fern;
    }
  }

  &--blue {
    .clipped-icon__wrapper {
      background-color: $color-dodger-blue;
    }
  }

  &--red {
    .clipped-icon__wrapper {
      background-color: $color-outrageous-orange;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    clip-path: polygon(0 13%, 100% 0, 100% 100%, 0 95%);

    @include viewport--sm {
      padding-top: 4px;
      padding-right: 16px;
      padding-bottom: 0;
      padding-left: 18px;
    }
  }
}
