.filter {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  list-style: none;

  @include viewport--sm {
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
    z-index: 3;

    flex-wrap: nowrap;
    width: 100vw;
    padding: 0 16px;


    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    flex-shrink: 0;

    label {
      display: inline-block;
      padding: 4px 11px;
      vertical-align: middle;

      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1.12px;
      text-transform: uppercase;
      color: $color-yukon-gold;

      cursor: pointer;
      border-radius: 11px;
      transition: background-color $default-transition-settings, color $default-transition-settings, padding $default-transition-settings;

      & {
        @media (hover: hover), screen and (min-width: 0\0) {
          &:hover,
          &:focus {
            color: $color-cinnabar;
          }
        }
      }
    }

    input {
      @include visually-hidden;

      &:checked + label {
        padding-right: 16px;
        padding-left: 16px;
        color: $color-dark-goldenrod;

        background-color: $color-white;
      }
    }

    @include viewport--sm {
      &:last-child {
        padding-right: 16px;
      }
    }
  }
}
