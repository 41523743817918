.catalog {
  position: relative;

  background-image:
    url("../img/bg/catalog-background.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 100% auto;

  @include retina {
    background-image:
      url("../img/bg/catalog-background@2x.png");
  }

  @include viewport--xl {
    background-size: 1920px auto;
  }

  @include viewport--md {
    background-image:
      url("../img/bg/catalog-background-tablet.png");

    background-position: 50% 14px;
    background-size: 1024px auto;

    @include retina {
      background-image:
        url("../img/bg/catalog-background-tablet@2x.png");
    }
  }

  @include viewport--sm {
    background-position: 50% 0;
    background-size: 150% auto;
  }


  &__inner {
     display: grid;
     gap: 40px;
     grid-template-columns: repeat(4, 1fr);
     grid-template-rows: auto;
     padding-top: 6vw;
     padding-bottom: 96px;

     @include viewport--xl {
       padding-top: 116px;
     }

     @include viewport--md {
       row-gap: 55px;
       column-gap: 20px;
       grid-template-columns: repeat(3, 1fr);
       padding-top: 175px;
       padding-bottom: 117px;
     }

     @include viewport--sm {
       row-gap: 35px;
       grid-template-columns: 100%;
       padding-top: 54px;
       padding-right: 9px;
       padding-left: 9px;
       padding-bottom: 35px;
     }
  }

  &__head {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    margin-top: -50px;

    @include viewport--md {
      grid-column: 1 / 1;
      margin-top: -102px;
    }

    @include viewport--sm {
      margin-top: 0;
      padding-bottom: 10px;
    }
  }

  &__title {
    margin: 0;

    font-size: 70px;
    line-height: 80px;
    color: $color-cinnabar;

    @include viewport--md {
      font-size: 50px;
      line-height: 60px;
    }
  }

  &__caption {
    margin: 0;
    margin-top: 12px;
    margin-left: 3px;

    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: $color-reef-gold;

    @include viewport--md {
      margin-top: 16px;
      line-height: 26px;
    }

    @include viewport--sm {
      margin-top: 0;
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__filter {
    margin-top: 12vw;

    @include viewport--xl {
      margin-top: 230px;
    }

    @include viewport--md {
      margin-top: 214px;
    }

    @include viewport--sm {
      margin-top: 40.6vw;
    }
  }

  &__product-item {
    display: flex;
    justify-content: center;
  }
}
