.manufactory {
  &__inner {
    padding-top: 80px;
    padding-right: 10px;

    @include viewport--md {
      padding-top: 18px;
      padding-right: 24px;
    }

    @include viewport--sm {
      padding-top: 46px;
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  h2,
  &__title {
    margin: 0;

    color: $color-white;

    @include viewport--sm {
      font-size: 26px;
      line-height: 36px;
    }
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: 76px;
    padding: 0;

    list-style: none;

    @include viewport--md {
      margin-top: 35px;
    }

    @include viewport--sm {
      flex-direction: column;
      margin-top: 26px;
    }
  }

  &__features-item {
    width: calc(100% / 3);
    padding-right: 50px;

    &:nth-of-type(3n) {
      padding-right: 10px;
    }

    &:not(&:nth-of-type(-n+3)) {
      margin-top: 50px;
    }

    h3 {
      margin: 0;
      margin-bottom: 26px;

      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      color: $color-madras;
    }

    p {
      margin: 0;

      font-weight: 700;
      font-size: 22px;
      line-height: 36px;
      color: $color-yukon-gold;

      & + p {
        margin-top: 1em;
      }
    }

    @include viewport--md {
      padding-right: 15px;

      h3 {
        margin-bottom: 6px;

        font-size: 22px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }

    @include viewport--sm {
      width: 100%;

      & + & {
        margin-top: 26px;
      }

      h3 {
        margin-bottom: 8px;
      }

      p {
        line-height: 22px;
      }
    }
  }

  &__features-icon {
    margin-bottom: 33px;

    @include viewport--md {
      margin-bottom: 36px;
    }

    @include viewport--sm {
      margin-bottom: 14px;
    }
  }
}
