.main {
  &--no-bg-img {
    background-image: none;

    .about {
      background-size: 0 0;
      background-color: $color-red-orange;
    }
  }
}
