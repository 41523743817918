.promo-card {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      .promo-card__video {
        &::before {
          opacity: 0;
        }

        img,
        video {
          filter: grayscale(0) saturate(1.2);
        }
      }
    }
  }

  .swiper-slide-active & {
    .promo-card__video {
      &::before {
        opacity: 0;
      }

      img,
      video {
        filter: grayscale(0) saturate(1.2);
      }
    }
  }

  &__video {
    position: relative;
    z-index: 1;

    padding-top: 50.125%;

    overflow: hidden;

    background-color: $color-grenadier;

    @include viewport--md {
      padding-top: 42.125%;
    }

    @include viewport--sm {
      padding-top: 46.125%;
    }

    img,
    video,
    &::before {
      position: absolute;
      top: -12.5%;
      left: 0;

      display: block;
      width: 100%;
      height: 125%;

      @include viewport--md {
        top: -15%;

        height: 130%;
      }
    }

    &::before {
      content: "";

      z-index: 1;

      transform: skew(0, 6.3deg);

      opacity: 1;
      mix-blend-mode: screen;
      // background-color: $color-grenadier;
      background-color: inherit;
      transition: opacity $custom-transition-settings, background-color $custom-transition-settings;
    }

    img,
    video {
      z-index: 0;

      opacity: 0.97;
      filter: grayscale(1);

      transition: filter $custom-transition-settings;

      transform: skew(0, 6.3deg);
      object-fit: cover;
    }

    @include viewport--md {
      img,
      video,
      &::before {
        transform: skew(0, 7deg);
      }
    }
  }

  &__caption {
    position: relative;
    z-index: 3;

    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    padding-top: 4.39%;
    padding-right: 8.77%;
    padding-bottom: 4.136%;
    padding-left: 6.52%;

    background-color: $color-grenadier;

    @include viewport--md {
      padding-top: 12px;
      padding-left: 30px;
      padding-right: 40px;
    }

    @include viewport--sm {
      padding-top: 4px;
      padding-left: 16px;
      padding-right: 14px;
    }
  }

  &__caption-text {
    margin: 0;
    max-width: 75%;
    margin-top: 5px;

    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: $color-white;

    @include viewport--md {
      max-width: 80%;

      font-size: 26px;
      line-height: 32px;
    }

    @include viewport--sm {
      font-size: 16px;
      line-height: 17px;
    }
  }

  &__caption-btn {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 103px;
    height: 103px;
    padding: 9px 13px;

    outline: none;
    border-radius: 50%;
    background-color: $color-red-orange;

    transition: opacity $default-transition-settings;
    transform: skew(0, 6.3deg);

    fill: $color-harley-davidson-orange;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    @include viewport--md {
      width: 54px;
      height: 54px;
      margin-top: 12px;

      transform: skew(0, 7deg);
    }

    @include viewport--sm {
      width: 43px;
      height: 43px;
      padding: 7px 10px;
    }

    svg {
      height: auto;
    }
  }
}
