.social {
  display: flex;
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    flex-shrink: 0;
    width: 47px;
    height: 57px;
    margin-right: 14px;

    &:last-of-type {
      margin-right: 0;
    }

    &--vk {
      .social__item-link {
        background-color: $color-royal-blue;
      }
    }

    &--fb {
      .social__item-link {
        background-color: $color-free-speech-blue;
      }
    }

    &--ig {
      .social__item-link {
        background-color: $color-wild-watermelon;
      }
    }

    &--ok {
      .social__item-link {
        background-color: $color-neon-carrot;
      }
    }
  }

  &__item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    clip-path: polygon(0 18%, 100% 0%, 100% 82%, 0% 100%);
    background-color: $color-royal-blue;

    outline: none;
    transition: opacity $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    svg {
      fill: $color-white;
    }
  }
}
