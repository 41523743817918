.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;

  @include viewport--sm {
    padding: 0 19px;
  }

  &--no-padding {
    @include viewport--sm {
      padding: 0;
    }
  }
}
