.banner {
  background-image:
    url("../img/bg/banner-background@1x.png");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;

  @include retina {
    background-image:
      url("../img/bg/banner-background@2x.png");
  }

  @include viewport--md {
    background-image:
      url("../img/bg/banner-background-tablet@1x.png");

    @include retina {
      background-image:
        url("../img/bg/banner-background-tablet@2x.png");
    }
  }

  @include viewport--md {
    background-position: 60% 100%;
  }

  &__inner {
    overflow: hidden;

    padding-top: 88px;
    padding-bottom: 195px;

    @include viewport--md {
      padding-top: 105px;
      padding-bottom: 127px;
    }

    @include viewport--sm {
      padding-top: 35px;
      padding-bottom: 44px;
    }
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include viewport--md {
      flex-direction: column;
    }
  }

  &__title {
    flex-shrink: 0;
    display: block;
    margin: 0;
    max-width: 720px;
    margin-right: 40px;
    margin-bottom: -15px;

    color: $color-cinnabar;
    letter-spacing: -2.2px;
    word-spacing: 10px;

    overflow: hidden;

    transform: skew(0, -6deg);
    z-index: 1;

    @include viewport--md {
      max-width: 610px;
      margin-right: 0;

      word-spacing: 0;
      letter-spacing: 0;
    }

    @include viewport--sm {
      max-width: 300px;
    }
  }

  &__title-animated {
    display: block;
    padding-bottom: 15px;
    letter-spacing: inherit;
    word-spacing: inherit;
  }

  &__description {
    max-width: 600px;
    margin: 0;
    margin-right: calc((100vw - 100%) * -.37);
    padding-bottom: 50px;

    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: normal;
    color: $color-metallic-bronze;

    transform: skew(0, -6deg);
    overflow: hidden;

    @include viewport--md {
      margin-top: 18px;
      margin-right: 0;
      margin-left: 4px;
      padding-bottom: 0;
    }

    @include viewport--sm {
      display: none;
    }
  }

  &__description-animated {
    display: block;
  }

  &__slider {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    margin-left: -20px;
    padding-bottom: 16px;

    transform: skew(0, -6.3deg);

    @include viewport--md {
      margin-top: 14px;
      margin-left: -13px;

      transform: skew(0, -7deg);
    }

    @include viewport--sm {
      margin-top: 25px;
      margin-left: -10px;
    }

    .swiper-pagination {
      align-self: center;
      display: none;
      margin-top: 26px;

      transform: skew(0, 7deg);

      @include viewport--sm {
        display: flex;
      }
    }

    .swiper-pagination-bullet  {
      flex-shrink: 0;
      display: block;
      width: 5px;
      height: 5px;
      margin-right: 5px;

      outline: none;
      background-color: $color-white;
      border-radius: 50%;

      &:last-of-type {
        margin-right: 0;
      }

      &-active {
        background-color: $color-grenadier;
      }
    }

    .swiper-slide {
      .promo-card {
        transform: translateX(calc(-100% - 40px));
      }

      .show & {
        .promo-card {
          transform: translateX(0);
        }
      }
    }

    .swiper-slide-prev,
    .swiper-slide-active,
    .swiper-slide-next {
      .promo-card {
        transform: translateX(calc(-100% - 40px));
      }

      .show & {
        .promo-card {
          animation-name: fil-slide;
          animation-duration: 0.6s;
          animation-timing-function: linear;

          animation-fill-mode: both;
        }
      }
    }

    .swiper-slide-prev {
      .promo-card {
        animation-delay: 0.3s;
      }
    }

    .swiper-slide-active {
      .promo-card {
        animation-delay: 0.8s;
      }
    }

    .swiper-slide-next {
      .promo-card {
        animation-delay: 1.3s;
      }
    }
  }

  &__slider-list {
    align-self: flex-start;
    display: flex;
    max-width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__slider-item {
    flex-shrink: 0;
    width: 838px;
    padding: 0 0 0 40px;
    overflow: hidden;

    .promo-card {
      height: 100%;
    }

    @include viewport--md {
      width: 512px;
      padding: 0 0 0 26px;
    }

    @include viewport--sm {
      width: 278px;
      padding: 0 0 0 10px;
    }

    &.overflow {
      overflow: visible;
    }
  }
}
