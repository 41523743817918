.detailed-card {

  &__img-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 100%;
    margin: 0 86px;
    padding: 85px 34px 42px;

    overflow-x: hidden;

    @media(max-width: 1279px) {
      margin: 0 11.8%;
    }

    @media(max-width: 1189px) {
      margin: 0 8.6%;
    }

    @media(max-width: 1119px) {
      margin: 0 5.9%;
    }

    @media(max-width: 1039px) {
      margin: 0 2.9%;
    }

    @media(max-width: 989px) {
      margin: 0 1%;
    }

    @media(max-width: 929px) {
      margin: 0;
    }

    @include viewport--sm {
      margin: 0 3%;
    }

    @media(max-width: 719px) {
      margin: 0;
    }

    .swiper-slide {
      transform: translateZ(0) scale(1);
      transition: transform 0.3s ease-in, filter $default-transition-settings;
    }

    .swiper-slide-active {
      transform: translateZ(0) scale(1.15);

      filter: drop-shadow(0 0 25px rgba($color-pueblo, 0.57));

      @media(max-width: 1279px) {
        transform: translateZ(0) scale(1.2);
        filter: drop-shadow(0 0 20px rgba($color-pueblo, 0.57));
      }

      @include viewport--md {
        transform: translateZ(0) scale(1.25);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.1);
      }
    }

    .swiper-pagination-bullet {
      width: 20px;
      height: 17px;
      margin-right: 15px;
      padding: 2px 0;


      text-align: center;
      line-height: 14px;
      font-size: 10px;
      font-weight: 600;
      color: $color-sea-pink;
      letter-spacing: 0.8px;
      text-transform: uppercase;

      opacity: 1;

      border: none;
      outline: none;
      border-radius: 20px;

      cursor: pointer;

      transition: color $default-transition-settings, background-color $default-transition-settings, padding $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }

      span {
        color: $color-white;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .swiper-pagination-bullet-active {
      padding: 2px 6px 2px 7px;

      color: $color-white;
      background-color: $color-thunderbird;
    }
  }

  &__swiper-pagination-wrapper {
    overflow: hidden;
  }

  &__img-list {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__img-item {
    position: relative;
    flex-shrink: 0;
    max-width: 240px;
    margin: 0 45px;
    padding: 10px 0;

    overflow: hidden;
    cursor: pointer;
    z-index: 1;

    transition: filter $default-transition-settings;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        filter: drop-shadow(0 0 35px rgba($color-tomato, 0.97));
      }
    }

    @media(max-width: 1279px) {
      max-width: 199px;
      margin: 0 40px;
    }

    @include viewport--md {
      margin: 0 40px;
    }

    @media(max-width: 939px) {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 20px rgba($color-tomato, 0.97));
        }
      }
    }

    @include viewport--sm {
      max-width: 175px;
      margin: 0 17px;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-tomato, 0.97));
        }
      }
    }
  }

  &__img-title {
    margin: 50px 18px 13px 0;

    color: $color-burnt-umber;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 1.3px;

    overflow: hidden;

    @media(max-width: 1279px) {
      margin-top: 62px;
    }

    @include viewport--sm {
      margin: 68px 5px 0px 0;
    }
  }

  &__img-title-animated {
    display: block;
  }

  &__content {
    display: flex;
    width: 100%;

    // @include viewport--md {
    //   padding: 0 40px;
    // }

    @media(max-width: 939px) {
      padding: 0 40px;
    }

    @include viewport--sm {
      flex-direction: column;
      padding: 0 19px;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    width: 50.8%;
    margin-right: 10%;
    padding-right: 4%;

    overflow: hidden;

    @media(max-width: 1279px) {
      margin-right: 2%;
      padding-right: 0;
      width: 50%;
    }

    @include viewport--sm {
      margin: 0;
      width: 100%;
    }
  }

  &__playlist-wrap {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 34px;

    overflow: hidden;

    iframe {
      width: 100%;
      height: 350px;
      border: none;
    }

    @media(max-width: 1279px) {
      padding-top: 42px;
      padding-right: 25px;
    }

    @include viewport--md {
      padding-right: 0;
    }

    @include viewport--sm {
      margin: 0;
      padding-top: 35px;
      width: 100%;
    }
  }

  &__playlist-iframe-wrap {
    width: 100%;
    height: 350px;
  }

  &__subtitle {
    display: inline-block;
    margin-bottom: 17px;

    color: $color-burnt-umber;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 1.3px;

    @media(max-width: 1279px) {
      margin-bottom: 13px;
    }
  }

  &__title {
    margin: 0 0 22px 0;

    color: $color-white;
    font-family: $font-family--title;
    font-size: 58px;
    line-height: 60px;

    @media(max-width: 1279px) {
      margin-bottom: 17px;

      font-size: 38px;
      line-height: 40px;
    }

    @include viewport--sm {
      margin-bottom: 8px;

      font-size: 24px;
      line-height: 26px;
    }
  }

  &__note {
    margin: 0;
    padding-bottom: 240px;

    color: $color-white;
    font-size: 22px;
    line-height: 30px;

    @media(max-width: 1279px) {
      max-width: 375px;

      font-size: 16px;
      line-height: 23px;
    }

    @include viewport--sm {
      max-width: none;
      padding-bottom: 104px;

      line-height: 24px;
    }

    @media(max-width: 579px) {
      padding-bottom: 63px;
    }
  }

  &__playlist-note {
    margin: 0 0 12px 0;

    color: $color-white;
    font-size: 22px;
    line-height: 27px;

    @media(max-width: 1279px) {
      margin-bottom: 5px;

      font-size: 16px;
      line-height: 23px;
    }

    @include viewport--sm {
      margin-bottom: 16px;
    }
  }

  &--green {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-jade, 0.97));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-carribian-green, 0.97));
        }
      }

      @include viewport--sm {
        margin: 0 25px
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-hunter-green;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-chinook;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-como;
    }
  }

  &--orange {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-tulip-tree, 0.76));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-cherokee, 0.97));
        }
      }

      @include viewport--sm {
        margin: 0 25px
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-pirate-gold;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-rajah;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-pirate-gold;
    }
  }

  &--beige {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-mint-julep-2, 0.97));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-rice-flower, 0.97));
        }
      }

      @include viewport--sm {
        margin: 0 25px
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-coriander;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-white-nectar;

      span {
        color: $color-emperor;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-coriander-2;

      span {
        color: $color-white;
      }
    }

    .detailed-card__title,
    .detailed-card__note,
    .detailed-card__playlist-note {
      color: $color-emperor-2;
    }
  }

  &--blue {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-curious-blue, 0.97));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @include viewport--sm {
        margin: 0 25px
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-summer-sky, 0.97));
        }
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-steel-blue;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-light-sky-blue;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-steel-blue-2;
    }
  }

  &--dark-brown {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-livid-brown, 0.97));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-congo-brown-2, 0.97));
        }
      }

      @include viewport--sm {
        margin: 0 25px
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-dorado;
    }

    .detailed-card__subtitle {
      color: $color-default-white;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-dorado-2;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-russett-2;
    }
  }

  &--light-blue {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-glacier-2, 0.97));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .detailed-card__description {
      width: 56.8%;

      @media(max-width: 1279px) {
        width: 50%;
      }

      @include viewport--sm {
        width: 100%;
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-spray, 0.97));
        }
      }

      @include viewport--sm {
        margin: 0 25px
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-blue-chill;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-blizzard-blue-2;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-blue-chill;
    }
  }

  &--pink {
    .detailed-card__img-slider .swiper-slide-active {
      filter: drop-shadow(0 0 25px rgba($color-careys-pink, 0.97));

      @include viewport--md {
        transform: translateZ(0) scale(1.15);
      }

      @include viewport--sm {
        transform: translateZ(0) scale(1.3);
      }
    }

    .perspective-mod img {
      @include viewport--sm {
        transform: rotateY(-7deg);
      }
    }

    .detailed-card__img-item {

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          filter: drop-shadow(0 0 25px rgba($color-careys-pink-2, 0.97));
        }
      }

      @include viewport--sm {
        margin: 0 25px
      }
    }

    .detailed-card__img-title {
      margin: 68px 18px 13px 0;
    }

    .detailed-card__img-title,
    .detailed-card__subtitle {
      color: $color-cannon-pink;
    }

    .detailed-card__img-slider .swiper-pagination-bullet {
      color: $color-blossom;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }

    .detailed-card__img-slider .swiper-pagination-bullet-active {
      color: $color-white;
      background-color: $color-mauve-taupe-2;
    }
  }

}
