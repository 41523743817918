.animation-inner {
  // fade-in-left
  .fil-16ms,
  .fil-20ms,
  .fil-30ms {
    transform: translateX(-100%);
  }

  .tastes__details-item.swiper-slide-active {

    .finl-18ms {
      // transform: translateX(0);

      animation-name: fil;
      animation-duration: 0.18s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .finl-25ms {
      // transform: translateX(0);

      animation-name: fil;
      animation-duration: 0.25s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .finl-35ms {
      // transform: translateX(0);

      animation-name: fil;
      animation-duration: 0.35s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .finl-slide {
      // transform: translateX(0);

      animation-name: fil-slide;
      animation-duration: 0.26s;
      animation-timing-function: linear;

      animation-fill-mode: both;
    }

    .del-600 {
      animation-delay: 0.6s;
    }

    .del-700 {
      animation-delay: 0.7s;
    }

    .del-800 {
      animation-delay: 0.80s;
    }

    .del-850 {
      animation-delay: 0.85s;
    }

    .del-900 {
      animation-delay: 0.9s;
    }

    .del-950 {
      animation-delay: 0.95s;
    }

    .del-1000 {
      animation-delay: 1s;
    }

    .del-1050 {
      animation-delay: 1.05s;
    }

    .del-1100 {
      animation-delay: 1.1s;
    }

    .del-1150 {
      animation-delay: 1.15s;
    }

    .del-1350 {
      animation-delay: 1.35s;
    }

    .del-1550 {
      animation-delay: 1.55s;
    }
  }
}
