.header {
  position: relative;

  background-color: $color-white;

  transition: background-color $default-transition-settings;

  @include viewport--sm {
    background-color: $color-white;

    &.active {
      background-color: $color-trinidad;

      .header__logo {
        filter: brightness(0) invert(1);
      }

      .header__nav {
        visibility: visible;
        background-color: $color-trinidad;
      }

      .header__nav-list {
        opacity: 1;
      }
    }
  }


  &:not(&--main) {
    @include viewport--md {
      .header__wrapper {
        min-height: $header-height--sm;
      }

      .header__logo {
        width: 161px;
        margin-right: 80px;
      }

      .header__nav {
        margin-top: 0;
        margin-bottom: 4px;
      }
    }

    @include viewport--sm {
      .header__logo {
        width: 135px;
        margin: 0 auto;
      }
    }
  }

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    width: 100%;

    background-color: transparent;

    @include viewport--sm {
      position: relative;
    }
  }

  &--filled-tablet {
    @include viewport--md {
      background-color: $color-white;
    }
  }

  // &--bgc-white {
  //   background-color: $color-white;

  //   .header__wrapper {
  //     min-height: 105px;
  //   }

  //   @include viewport--md {
  //     .header__wrapper {
  //       min-height: 72px;
  //     }
  //   }

  //   @include viewport--sm {
  //     .header__wrapper {
  //       min-height: 68px;
  //     }

  //     &.active {
  //       .header__wrapper {
  //         min-height: 70px;
  //       }
  //     }
  //   }
  // }

  &__wrapper {
    display: flex;
    align-items: center;
    min-height: $header-height--lg;

    @include viewport--sm {
      padding-right: 13px;
      padding-left: 13px;
      min-height: $header-height--sm;

      &::before {
        content: "";

        width: 43px;
        height: 43px;
        display: block;

        pointer-events: none;
        user-select: none;
      }
    }
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 65px;

    transition: filter $default-transition-settings, opacity $default-transition-settings;

    &[href] {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 0.7;
        }
      }
    }

    img {
      display: block;
      object-fit: contain;
    }

    @include viewport--md {
      width: 224px;
      margin-right: 100px;
      margin-bottom: 0;
    }

    @include viewport--sm {
      width: 135px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__toggle {
    display: none;

    @include viewport--sm {
      display: flex;
    }
  }

  &__nav {
    margin-top: 10px;

    @include viewport--sm {
      position: absolute;
      top: $header-height--sm;
      left: 0;
      z-index: 10;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100vw;
      height: 100%;
      min-height: calc(100vh - #{$header-height--sm});
      min-height: calc(100 * var(--vh, 1vh) - #{$header-height--sm});
      margin-top: 0;
      padding: 12px;
      overflow-y: auto;

      background-color: inherit;
      visibility: hidden;

      user-select: none;
      transition: transform $default-transition-settings, visibility $default-transition-settings, opacity $default-transition-settings, background-color $default-transition-settings;
      animation-name: disableBreakpointAnimation;
      animation-duration: 0.6s;
    }
  }

  &__nav-list {
    @include viewport--sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 100%;
      padding-bottom: 50px;

      text-align: center;

      opacity: 0;
      transition: opacity $default-transition-settings;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
