.burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;

  outline: none;
  border-radius: 50%;
  background-color: $color-trinidad;

  transition: background-color $default-transition-settings, opacity $default-transition-settings;

  span {
    position: absolute;

    display: block;
    width: 19px;
    height: 2px;
    background-color: $color-white;

    transition: transform $default-transition-settings, opacity $default-transition-settings;

    &:first-child {
      transform: translateY(7px);
    }

    &:last-child {
      transform: translateY(-7px);
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.8;

      span:first-child {
        transform: translateY(5px);
      }

      span:last-child {
        transform: translateY(-5px);
      }
    }
  }

  &.open {
    background-color: $color-white;

    span {
      background-color: $color-trinidad;
      transition: transform $default-transition-settings, opacity $default-transition-settings;
    }

    span:nth-child(1) {
      transform: translateY(0) rotate(45deg) scale(1.2, 1);
    }

    span:nth-child(2) {
      opacity: 0;
      transform: translateX(100%);
    }

    span:nth-child(3) {
      transform: translateY(0) rotate(-45deg) scale(1.2, 1);
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        span:nth-child(1) {
          transform: translateY(0) rotate(135deg) scale(1,2, 1);
        }

        span:nth-child(3) {
          transform: translateY(0) rotate(-135deg) scale(1,2, 1);
        }
      }
    }
  }
}
