.h1,
.h2 {
  font-family: $font-family--title;
  font-weight: 700;
}

.h1 {
  font-size: 80px;
  line-height: 70px;

  @include viewport--md {
    font-size: 65px;
    line-height: 60px;
  }

  @include viewport--sm {
    font-size: 33px;
    line-height: 30px;
  }
}

.h2 {
  font-size: 50px;
  line-height: 70px;
}
