.footer {
  background-color: $color-grenadier;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 160px;
    padding-top: 28px;
    padding-bottom: 16px;

    @include viewport--md {
      min-height: 158px;
      padding-top: 36px;
      padding-bottom: 40px;
    }

    @include viewport--sm {
      flex-direction: column;
      align-content: space-between;
      min-height: 244px;
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }

  &__copyright,
  &__credits {
    margin: 0;
    margin-bottom: 12px;

    font-weight: 600;
    font-size: 18px;
    line-height: normal;
    color: rgba($color-white, 0.7);

    a[href] {
      outline: none;
      transition: opacity $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 0.7;
        }
      }
    }

    @include viewport--md {
      margin-bottom: 0;
    }

    @include viewport--sm {
      text-align: center;
    }
  }

  &__copyright {
    @include viewport--sm {
      order: 2;

      font-size: 14px;
    }
  }

  &__credits {
    @include viewport--sm {
      order: 1;
      margin-bottom: 36px;
    }
  }

  &__social {
    margin: 0 20px;

    @include viewport--md {
      margin: 0 10px;
    }

    @include viewport--sm {
      order: 0;
      margin: 0 0 40px;
    }
  }

  &__credits-link {
    display: inline-flex;
    vertical-align: middle;

    svg {
      fill: $color-white;
    }
  }
}
