.about {
  color: $color-white;

  background-image:
    url("../img/bg/about-background.png");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;

  @include retina {
    background-image:
      url("../img/bg/about-background@2x.png");
  }

  @include viewport--xl {
    background-size: auto 100%;
  }

  @include viewport--md {
    background-position: 70% 0;
  }

  @include viewport--sm {
    background-size: cover;
    background-position: 0% 100%;
  }

  &__inner {
    padding-top: 114px;
    padding-bottom: 240px;

    @include viewport--md {
      padding-top: 70px;
      padding-bottom: 206px;
    }

    @include viewport--sm {
      padding-top: 16px;
      padding-right: 12px;
      padding-bottom: 90px;
      padding-left: 12px;
    }
  }

  &__text {
    display: flex;
    align-items: flex-start;

    @include viewport--sm {
      display: block;
    }
  }

  &__text h2,
  &__title {
    width: calc(100% * 1 / 3);
    padding-right: 20px;
    flex-shrink: 0;
    margin: 0;

    font-size: 60px;
    line-height: 80px;

    @include viewport--md {
      font-size: 50px;
      line-height: 70px;
    }

    @include viewport--sm {
      font-size: 26px;
      line-height: 36px;
    }
  }

  &__description {
    width: calc(100% * 2 / 3);
    margin-left: auto;
    padding-top: 20px;
    padding-right: 70px;

    font-weight: 700;
    font-size: 22px;
    line-height: 30px;

    p {
      margin: 0;

      & + p {
        margin-top: 1em;
      }
    }

    @include viewport--md {
      width: 62%;
      padding-top: 27px;
      padding-right: 50px;

      font-size: 16px;
      line-height: 22px;
    }

    @include viewport--sm {
      width: auto;
      margin-top: 32px;
      margin-left: 0;
      padding-top: 0;
      padding-right: 0;
    }
  }

  &__logo {
    display: block;
    width: 254px;
    height: 89px;
    margin-bottom: 40px;

    img {
      display: block;
    }

    @include viewport--md {
      margin-bottom: 20px;
    }
  }

  &__achievements {
    margin-top: 30px;

    @include viewport--md {
      margin-top: 67px;
    }

    @include viewport--sm {
      margin-top: 40px;
    }
  }
}
