.animation-group,
// .animation-custom-group,
.banner {
  // fade-in-left
  .fil-12ms,
  .fil-16ms,
  .fil-20ms,
  .fil-25ms,
  .fil-30ms,
  .fil-40ms,
  .fil-50ms,
  .fil-60ms,
  .fil-70ms,
  .fil-80ms,
  .fil-85ms,
  .fil-95ms {
    transform: translateX(-100%);
  }

  .fil-slide {
    transform: translateX(calc(-100% - 40px));
  }

  .fil-slide-sm {
    transform: translateX(calc(-100% - 130px));
  }

  // move-in-top-100
  .mit-100 {
    opacity: 0;
    transform: translateY(0) rotate(-12deg);
  }

  // move-to-bottom-100
  .mtb-50 {
    opacity: 0;
    transform: translateY(0) rotate(-24deg);
  }

  // fade-in
  .fi {
    opacity: 0;
  }

  .pulse {
    transform: translateZ(0);
    transform: scale(0.2);
    opacity: 0;
  }

  .spin {
    transform: rotate(0);
  }

  .d-100 {
    animation-delay: 0.1s;
  }

  .d-150 {
    animation-delay: 0.15s;
  }

  .d-200 {
    animation-delay: 0.2s;
  }

  .d-250 {
    animation-delay: 0.25s;
  }

  .d-260 {
    animation-delay: 0.26s;
  }

  .d-300 {
    animation-delay: 0.3s;
  }

  .d-350 {
    animation-delay: 0.35s;
  }

  .d-400 {
    animation-delay: 0.4s;
  }

  .d-450 {
    animation-delay: 0.45s;
  }

  .d-500 {
    animation-delay: 0.5s;
  }

  .d-520 {
    animation-delay: 0.52s;
  }

  .d-580 {
    animation-delay: 0.58s;
  }

  .d-600 {
    animation-delay: 0.6s;
  }

  .d-620 {
    animation-delay: 0.62s;
  }

  .d-650 {
    animation-delay: 0.65s;
  }

  .d-700 {
    animation-delay: 0.7s;
  }

  .d-750 {
    animation-delay: 0.75s;
  }

  .d-780 {
    animation-delay: 0.78s;
  }

  .d-800 {
    animation-delay: 0.8s;
  }

  .d-850 {
    animation-delay: 0.85s;
  }

  .d-900 {
    animation-delay: 0.9s;
  }

  .d-1000 {
    animation-delay: 1s;
  }

  .d-1040 {
    animation-delay: 1.4s;
  }

  .d-1100 {
    animation-delay: 1.1s;
  }

  .d-1200 {
    animation-delay: 1.2s;
  }

  .d-1300 {
    animation-delay: 1.3s;
  }

  .d-1400 {
    animation-delay: 1.4s;
  }

  .d-2000 {
    animation-delay: 2s;
  }

  &.show {

    .flip {
      transform: skew(0, 0) !important;

      animation-name: flip-slide !important;
      animation-duration: 0.8s !important;
      animation-delay: 0s !important;
      animation-timing-function: linear !important;


      animation-fill-mode: both !important;
    }

    .fil-12ms {
      animation-name: fil;
      animation-duration: 0.12s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-16ms {
      animation-name: fil;
      animation-duration: 0.16s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-20ms {
      animation-name: fil;
      animation-duration: 0.2s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-25ms {
      animation-name: fil;
      animation-duration: 0.25s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-30ms {
      animation-name: fil;
      animation-duration: 0.3s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-40ms {
      animation-name: fil;
      animation-duration: 0.4s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-50ms {
      animation-name: fil;
      animation-duration: 0.5s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-60ms {
      animation-name: fil;
      animation-duration: 0.6s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-70ms {
      animation-name: fil;
      animation-duration: 0.7s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-80ms {
      animation-name: fil;
      animation-duration: 0.8s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-85ms {
      animation-name: fil;
      animation-duration: 0.85s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-95ms {
      animation-name: fil;
      animation-duration: 0.95s;
      animation-timing-function: ease;

      animation-fill-mode: both;
    }

    .fil-slide {
      animation-name: fil-slide;
      animation-duration: 0.6s;
      animation-timing-function: linear;

      animation-fill-mode: both;
    }

    .fil-slide-sm {
      animation-name: fil-slide-sm;
      animation-duration: 0.26s;
      animation-timing-function: linear;

      animation-fill-mode: both;
    }

    .mit-100 {
      transform: translateY(-100px);

      animation-name: move-in-top-100;
      animation-duration: 2.3s;
      animation-timing-function: linear;
      animation-delay: 0.4s;
      animation-fill-mode: both;

      @include viewport--sm {
        animation-name: move-in-top-35;
      }
    }

    .mtb-50 {
      transform: translateY(50px);

      animation-name: move-to-bottom-50;
      animation-duration: 2.3s;
      animation-timing-function: linear;
      animation-delay: 0.4s;
      animation-fill-mode: both;
    }

    .fi {
      animation-name: fade-in;
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;
    }

    .fi-2 {
      animation-name: fade-in;
      animation-duration: 0.1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: initial;
      animation-fill-mode: both;
    }

    .hero__preloader-img {
      display: none;
    }

  }

  &.hide-animation {

    &:not(.swiper-slide-active) {
      .fil-slide-sm {
        animation-name: for-slide-sm;
      }
    }

    .swiper-slide-active  {
      .fil-slide-sm {
        animation-name: fot-slide-sm;
        animation-duration: 0.4s;
      }

    }

    .fil-12ms,
    .fil-16ms,
    .fil-20ms,
    .fil-25ms,
    .fil-30ms,
    .fil-40ms,
    .fil-50ms {
      animation-name: for;
    }

    .fi {
      animation-name: fade-out;
    }
  }
}

