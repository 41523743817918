.breadcrumbs {

  @include viewport--sm {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__link {
    position: relative;
    display: block;
    margin-left: 8px;
    padding-right: 11px;

    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    transition: color $default-transition-settings;

    &::before {
      content: "|";
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      line-height: 20px;
      color: $color-de-york;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:focus,
      &:hover {
        color: $color-hunter-green;
      }
    }

    .breadcrumbs__item:first-child {
      margin: 0;
    }

    .breadcrumbs__item:last-child {
      color: $color-de-york;

      &::before {
        content: none;
      }
    }
  }

}
