.product {
  display: block;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      .product__image {
        filter: drop-shadow(0 0 30px rgba(#ffdf4c, 0.97));
        transform: translateZ(0);
      }

      h3 {
        color: $color-white;
      }
    }
  }


  &__image {
    display: flex;
    justify-content: center;
    width: 100%;

    padding-left: 5px;
    transition: filter $default-transition-settings;

    img {
      flex-shrink: 0;
      display: block;
      width: 100%;
      width: 220px;
      margin: auto;

      object-fit: contain;
    }

    @include viewport--md {
      padding: 0;

      img {
        width: 182px;
      }
    }

    @include viewport--sm {
      padding-right: 10px;

      img {
        width: 220px;
      }
    }
  }

  &__description {
    margin-top: 22px;
    padding-left: 5px;

    h3 {
      margin: 0;

      font-weight: 700;
      font-size: 22px;
      color: $color-raw-umber;
      text-align: center;

      transition: color $default-transition-settings;
    }

    h3 + p {
      margin-top: 17px;
    }

    p {
      margin: 0 auto;

      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #926a00;
      text-align: center;
    }

    @include viewport--md {
      padding: 0 10px;
    }

    @include viewport--sm {
      padding-left: 0;

      p {
        max-width: 300px;
      }
    }
  }
}
