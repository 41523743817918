.page-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -160px;
  min-height: 840px;

  flex-grow: 1;

  background-image:
  url("../img/bg/404-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $color-selective-yellow;

  @include retina {
    background-image:
      url("../img/bg/404-bg.jpg");
  }

  @include viewport--md {
    background-position: 67% top;
  }

  @include viewport--sm {
    min-height: 530px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin: -266px 0 0;

    color: $color-cinnabar;
    font-family: $font-family--title;
    font-size: 245px;
    line-height: 240px;

    @include viewport--md {
      margin: -196px 0 0;
    }

    @include viewport--sm {
      margin: -186px 0 0;

      font-size: 98px;
      line-height: 110px;
    }
  }

  &__content-text {
    margin: 10px 0 30px 0;
    max-width: 715px;

    color: $color-reef-gold;
    font-size: 23px;
    line-height: 37px;
    text-align: center;
    letter-spacing: -0.3px;

    @include viewport--sm {
      margin: 10px 0 25px;

      font-size: 18px;
      line-height: 26px;
    }
  }

  &__link {
    margin-left: 8px;

    color: $color-punga;
    font-size: 18px;
    font-weight: bold;

    transition: opacity $default-transition-settings, color $default-transition-settings;

    &.active {
      color: $color-sunglow;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }
}
