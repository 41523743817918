@keyframes fil {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fot-slide-sm {
  0% {
    transform: translateY(0) scale(1) skew(0,6.8deg);
  }

  90% {
    opacity:  1;
  }

  100% {
    transform: translateY(-75%) scale(0.84) skew(0,6.8deg);
    opacity: 0;
  }
}

@keyframes for {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes fil-slide {
  0% {
    transform: translateX(calc(-100% - 40px));
  }

  100% {
    transform: translateX(0);
  }
}


@keyframes fil-slide-sm {
  0% {
    transform: translateX(calc(-100% - 130px - 30px)) skew(0,6.8deg); // Минус паддинг и ширина тени
  }

  100% {
    transform: translateX(0) skew(0,6.8deg);
  }
}

@keyframes for-slide-sm {
  0% {
    transform: translateX(0) skew(0,6.8deg);
  }

  100% {
    transform: translateX(calc(100% + 130px)) skew(0,6.8deg);
  }
}

@keyframes move-in-top-100 {
  0% {
    opacity: 0;
    transform: translateY(0) rotate(-12deg);
  }

  15% {
    opacity: 1;
    transform: translateY(0) rotate(-12deg);
  }

  100% {
    opacity: 1;
    transform: translateY(-100px) rotate(-12deg);
  }
}

@keyframes move-in-top-35 {
  0% {
    opacity: 0;
    transform: translateY(0) rotate(-12deg);
  }

  15% {
    opacity: 1;
    transform: translateY(0) rotate(-12deg);
  }

  100% {
    opacity: 1;
    transform: translateY(-35px) rotate(-12deg);
  }
}

@keyframes move-to-bottom-50 {
  0% {
    opacity: 0;
    transform: translateY(0) rotate(-24deg)
  }

  15% {
    opacity: 1;
    transform: translateY(0) rotate(-24deg);
  }

  100% {
    opacity: 1;
    transform: translateY(50px) rotate(-24deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    transform: scale3d(1.5, 1.5, 1.5)
  }
}

@keyframes shake-slide {
  0% {
    transform: rotate(1deg);
  }

  50% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0.5deg);
  }
}

@keyframes flip-slide {
  0% {
    transform: rotate(0deg) perspective(1000px) rotateY(0deg) skew(0, 0);
    animation-timing-function: ease-in;
  }

  9% {
    transform: rotate(2deg) perspective(1000px) rotateY(0deg) skew(0, 0);
    animation-timing-function: linear;
  }

  16% {
    transform: rotate(-3deg) perspective(1000px) rotateY(0deg) skew(0, 0);
    animation-timing-function: linear;
  }

  20% {
    transform: rotate(0deg) perspective(1000px) rotateY(0deg) skew(0, 0);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(2000px) rotateY(-90deg) skew(0, -10deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(1200px) rotateY(-180deg) skew(0, -13deg);
    animation-timing-function: linear;
  }

  80% {
    transform: perspective(2000px) rotateY(-270deg) skew(0, 0);
    animation-timing-function: linear;
  }

  100% {
    transform: perspective(5000px) rotateY(-360deg) skew(0, 0);
    animation-timing-function: ease-out;
  }
}

// @keyframes pulse {
//   0% {
//     opacity: 0;
//   }

//   25% {
//     opacity: 1;
//   }

//   100% {
//     transform: scale(1);
//     opacity: 0;
//   }
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0);
//   }

//   100% {
//     transform: rotate(360deg)
//   }
// }
