.bg {
  // transition: background $default-transition-settings;

  &--bgc-red {
    background-color: $color-grenadier;
  }

  &--dotted-red {
    background-color: $color-grenadier;
    background-image: radial-gradient($color-medium-carmin 24%, transparent 24%),
                      radial-gradient($color-medium-carmin 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-salmon;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-flamingo;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-salmon;
        }
      }
    }
  }

  &--dotted-yellow {
    background-color: $color-selective-yellow;
    background-image:
      radial-gradient($color-selective-yellow-2 24%, transparent 24%),
      radial-gradient($color-selective-yellow-2 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;
  }

  &--dotted-green {
    background-color: $color-fern-2;
    background-image:
      radial-gradient($color-ocean-green 24%, transparent 24%),
      radial-gradient($color-ocean-green 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-de-york;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-de-york;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-chinook;
        }
      }
    }
  }

  &--dotted-blue {
    background-color: $color-malibu;
    background-image:
      radial-gradient($color-picton-blue 24%, transparent 24%),
      radial-gradient($color-picton-blue 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-light-sky-blue;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-picton-blue-2;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-columbia-blue;
        }
      }
    }
  }

  &--dotted-beige {
    background-color: $color-coconut-cream;
    background-image:
      radial-gradient($color-mint-julep 24%, transparent 24%),
      radial-gradient($color-mint-julep 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-white-nectar;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-sisal;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-corn-silk;
        }
      }
    }
  }

  &--dotted-orange {
    background-color: $color-carrot-orange;
    background-image:
      radial-gradient($color-buttercup 24%, transparent 24%),
      radial-gradient($color-buttercup 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-rajah;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-ochre;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-chardonnay;
        }
      }
    }
  }

  &--dotted-dark-brown {
    background-color: $color-crater-brown;
    background-image:
      radial-gradient($color-congo-brown 24%, transparent 24%),
      radial-gradient($color-congo-brown 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-dorado-2;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-russett-2;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-russett;
        }
      }
    }
  }

  &--dotted-light-blue {
    background-color: $color-viking;
    background-image:
      radial-gradient($color-fountain-blue 24%, transparent 24%),
      radial-gradient($color-fountain-blue 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-blizzard-blue-2;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-cadet-blue;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-blizzard-blue;
        }
      }
    }
  }

  &--dotted-pink {
    background-color: $color-tapestry;
    background-image:
      radial-gradient($color-turkish-rose 24%, transparent 24%),
      radial-gradient($color-turkish-rose 24%, transparent 24%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px, 50px 50px;

    .breadcrumbs__item:last-child .breadcrumbs__link,
    .breadcrumbs__link::before {
      color: $color-blossom;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .tastes__details-close-btn {
      border: 2px solid $color-mauve-taupe;

      transition: border $default-transition-settings;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          border-color: $color-chantilly;
        }
      }
    }
  }
}
