.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  background-color: $color-grenadier;
  background-image: radial-gradient($color-medium-carmin 24%, transparent 24%),
                    radial-gradient($color-medium-carmin 24%, transparent 24%);
  background-position: 0 0, 25px 25px;
  background-size: 50px 50px, 50px 50px;


  transition: visibility $modal-transition-settings, opacity $modal-transition-settings;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 300px;
  //   height: 300px;

  //   transform: translate(-50%, -50%);


  //   background-image: url('../img/content/gif/sloboda_preloader.gif');
  //   background-repeat: no-repeat;
  //   background-position: center;

  // }

  img {
    width: 300px;
    height: 300px;

    -webkit-transform: rotateX(0);

    @include viewport--sm {
      width: 180px;
      height: 180px;
    }
  }

  &--is-loading {
    visibility: visible;
    opacity: 1;
  }

  &--is-hidden {
    visibility: hidden;
    opacity: 0;
  }
}
