.product-card-page {
  padding-top: 50px;
  padding-bottom: 0;

  .breadcrumbs {
    margin-bottom: 25px;
  }

  .tastes {
    overflow: hidden;
    padding-top: 234px;

    @include viewport--sm {
      padding-top: 136px;
    }
  }

  .detailed-card__img-slider {
    padding: 71px 34px 96px;
    margin: 0 111px;

    @media (max-width: 1279px) {
      margin: 0 11.8%;
    }

    @media(max-width: 1139px) {
      margin: 0 7.9%;
    }

    @media(max-width: 989px) {
      margin: 0 6.3%;
    }

    @media(max-width: 959px) {
      margin: 0 4.7%;
    }

    @media(max-width: 925px) {
      margin: 0 4%;
    }

    @media(max-width: 915px) {
      margin: 0 2.5%;
    }

    @media(max-width: 877px) {
      margin: 0 4.6%;
    }

    @media(max-width: 839px) {
      margin: 2.5%;
    }

    @media(max-width: 805px) {
      margin: 1.5%;
    }

    @media(max-width: 789px) {
      margin: 0;
    }
  }

  .detailed-card__description {
    padding-right: 1.5%;
  }

  .detailed-card__playlist-wrap {
    padding-top: 45px;
  }

  .detailed-card__playlist-note {
    margin: 0 0 28px;
  }

  .tastes__details-slider-btns-wrap {
    bottom: 200px;
    left: 0px;
  }

  .detailed-card__img-item {
    @media(max-width: 859px) {
      margin: 0 23px
    }

    @include viewport--md {
      margin: 0 25px;
    }
  }


  @media (max-width: 939px) {
    .detailed-card__content {
      padding: 0;
    }
  }

  @media(max-width: 877px) {
    padding-left: 0;
    padding-right: 0;

    .breadcrumbs,
    .detailed-card__content {
      padding-left: 40px;
      padding-right: 40px;
    }

    .tastes__details-slider-btns-wrap {
      left: 40px;
    }
  }

  @include viewport--md {
    padding-top: 41px;
    padding-bottom: 98px;

    .tastes {
      padding: 85px 0 0;
    }

    .detailed-card__img-title {
      margin-top: 66px;
    }

    .detailed-card__img-slider {
      padding: 102px 34px 22px;
    }

    .detailed-card__subtitle {
      margin-bottom: 24px;
    }

    .detailed-card__title {
      margin-bottom: 13px;
    }

    .detailed-card__note {
      max-width: 382px;
    }

    .detailed-card__playlist-wrap {
      padding-right: 0;
      padding-top: 52px;
    }

    .detailed-card__playlist-note {
      margin: 0 0 4px;
    }

    .detailed-card__description {
      margin-right: 8%;
    }

    .tastes__details-slider-btns-wrap {
      bottom: 190px;
    }
  }

  @include viewport--sm {
    padding-top: 25px;
    padding-bottom: 26px;

    .tastes {
      padding: 60px 0 0;
    }

    .breadcrumbs,
    .detailed-card__content {
      padding-left: 19px;
      padding-right: 19px;
    }

    .detailed-card__img-item {
      max-width: 140px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .tastes .detailed-card__img-slider {
      margin-top: 98px;
    }

    .detailed-card__img-title {
      margin-top: 29px;
      margin-bottom: 16px;
    }

    .detailed-card__img-slider {
      padding: 12px 0 23px;
      margin: 0 9.7%;

      @media(max-width: 727px) {
        margin: 7.4%;
      }

      @media(max-width: 689px) {
        margin: 6%;
      }

      @media(max-width: 669px) {
        margin: 4%;
      }

      @media(max-width: 649px) {
        margin: 2.2%;
      }

      @media(max-width: 619px) {
        margin: 0;
      }
    }

    .detailed-card__subtitle {
      margin-bottom: 15px;
    }

    .detailed-card__title {
      margin-bottom: 10px;
    }

    .detailed-card__playlist-wrap {
      padding-top: 40px;
    }

    .detailed-card__playlist-note {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 25px;
    }

    .tastes__details-slider-btns-wrap {
      bottom: 445px;
      left: 19px;

      @media(max-width: 499px) {
        bottom: 445px;
      }
    }
  }
}
