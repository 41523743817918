.taste-card {
  position: relative;
  display: flex;
  // width: auto;
  width: 301px;
  height: auto;

  // transform: skew(0,6.8deg);

  // transition: filter $default-transition-settings;

  // filter: drop-shadow(0 0 30px rgba($color-pueblo, 0.57));

  &::before {
    content: "";
    position: absolute;
    top: 17px;
    left: 7px;

    width: 92%;
    height: 90%;

    background: $color-emperor;
    z-index: -1;
    transition: filter $default-transition-settings;
    border-radius: 25%;

    // filter: drop-shadow(0 0 30px rgba($color-pueblo, 0.77));
    box-shadow: 0 0 30px rgba($color-pueblo, 0.77);
  }

  @include viewport--sm {
    &::before {
      top: 11px;
      height: 85%;
    }
  }

  &__img {
    display: flex;
    width: 301px;
    height: auto;
  }
}
